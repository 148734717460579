cluster-l > * {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: calc(var(--s1) / 2 * -1);
}

cluster-l > * > * {
  margin: calc(var(--s1) / 2);
}