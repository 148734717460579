frame-l {
  display: block;
  position: relative;
  padding-bottom: calc(6 / 9 * 100%);
}

frame-l > * {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

frame-l > img, 
frame-l > video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}